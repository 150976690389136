import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';
import { MessageService } from './MessageService';


const getContacts = () => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts`);
};

const getContactById = (id) => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/${id}`);
};

const updateContact = (data) => {
  return AxiosInstance.put(`${Config.API_URL}/api/Contacts`, data);
};

const createContact = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts`, data);
};

const deleteContact = (id) => {
  return AxiosInstance.delete(`${Config.API_URL}/api/Contacts/${id}`);
};

const uploadFile = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/uploadFile`, data);
}
const outlookFile = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/Import?source=Outlook`, data);
}
const importContact = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/bulkInsert`, data);
}
const importCorrectContact = (data, fileNmae, save) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/bulkInsert?saveRecords=${save}&fileName=${fileNmae}`, data);
}

const unBlockAll = (data) => {
  return AxiosInstance.patch(`${Config.API_URL}/api/Contacts/UnBlockAll`, data);
}

const blockUnBlockNumber = (number, isBlocked) => {
  MessageService.blockUnBlockNumber(number, isBlocked);
  number = number.replace(/\+/g, "");
  return AxiosInstance.patch(`${Config.API_URL}/api/Contacts/${number}/SetBlocked/${isBlocked}`);
};
const getBlockedContacts = () => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/Blocked`);
}

const getUnsubscribeContacts = () => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/UnSubscribed`);
}
const setUnreadMessageCount = (number, count) => {
  // MessageService.setUnreadMessageCount(number, count);
  number = number.replace(/\+/g, "");
  return AxiosInstance.patch(`${Config.API_URL}/api/Contacts/${number}/SetRead/${count}`).then(res => {
    return res;
  });
};


const contactsMassActionDelete = (action, data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/MassActions?action=${action}`, data);
}

const contactsMassAction = (action, data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/MassAction?action=${action}`, data);
}

const shareContact = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/Share`, data);
}
function editGroupchatName({ Id, name }) {
  return AxiosInstance.patch(`${Config.API_URL}/api/Messages/UpdateGroupChat?groupChatId=${Id}&groupChatName=${name}`);
}
function editCampaginName({ Id, name }) {
  return AxiosInstance.patch(`${Config.API_URL}/api/Campaign/UpdateCampaign?campaignId=${Id}&campaignName=${name}`);
}

const copyContactToCLio = () => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/CopyToClio`);
}

const copyContactToHubSpot = ()=>{
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/CopyToHubSpot`);
}

const allContactsImportReports = () => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/ContactImportReports`);
}


const getLatestContactsImportReports = (type: string) => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/ContactImportReport?source=${type}`);
}

const getContactImportReportById = (id: string) => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/ContactImportReport/${id}`);
}



export const ContactService = {
  getContacts,
  getContactById,
  updateContact,
  createContact,
  deleteContact,
  importContact,
  importCorrectContact,
  uploadFile,
  blockUnBlockNumber,
  getBlockedContacts,
  setUnreadMessageCount,
  unBlockAll,
  getUnsubscribeContacts,
  contactsMassAction,
  shareContact,
  contactsMassActionDelete,
  outlookFile,
  editGroupchatName,
  editCampaginName,
  copyContactToCLio,
  allContactsImportReports,
  getLatestContactsImportReports,
  getContactImportReportById,
  copyContactToHubSpot,
};